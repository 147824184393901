import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import CorsoPage from "../components/slugPages/corsoPage"

const BlogPostTemplate = ({ data, location }) => (
  <Layout
    pageMeta={{
      title: `${data.strapiCorsi.titolo}`,
      keywords: [`${data.strapiCorsi.titolo}`],
      description: `${data.strapiCorsi.titolo}`,
      link: `/corsi/${data.strapiCorsi.slug}`,
    }}
    location={location}
  >
    <CorsoPage post={data.strapiCorsi} location={location} />
  </Layout>
)
export default BlogPostTemplate

export const corsoQuery = graphql`
  query CorsoTemplateQuery($slug: String!) {
    strapiCorsi(slug: { eq: $slug }) {
      strapiId
      slug
      titolo
      testo
      foto {
        name
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
